.order-status__header {
  padding-bottom: 1em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: -5px 6px 5px 0px rgba(189, 189, 189, 0.47);
  -webkit-box-shadow: -5px 6px 5px 0px rgba(189, 189, 189, 0.47);
  -moz-box-shadow: -5px 6px 5px 0px rgba(189, 189, 189, 0.47);

  .header__title {
    display: flex;
    justify-content: center;
    img {
      height: 5em;
      width: auto;
    }
    &-subtitle {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      padding: 0;
      justify-content: center;
      align-items: flex-start;
      .order {
        font-size: 1.5em;
      }
    }
  }
  .header__driver {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}
.order-status__main {
  // background-color: lighten($color: grey, $amount: 40);
  margin-top: 1em;
  width: 50%;
  // position: absolute;
  // opacity: 0.9;
  // z-index: 100;
  .status {
    display: flex;
    min-height: 6em;
    // background-color: greenyellow;
    .datetime {
      .date {
        display: flex;
        .icon {
          font-size: 2em;
        }
        .date__main {
          width: 7em;
        }
        /* .active {
          color: $primary-color;
        } */
        small {
          padding-top: 0.75em;
        }
      }

      .vertical {
        display: flex;
        flex-wrap: wrap;
        // border-color: $primary-color;
        border-left: 3px solid;
        min-height: 60%;
        top: 100%;
        margin-left: 11%;
        padding-left: 1em;
        margin-top: 0.25em;
        margin-bottom: 0.25em;
      }
    }
    .status__info {
      margin-left: 1em;
      display: inline-block;
      padding-top: 0.5em;
      strong {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
      small {
        text-align: justify;
        color: lighten($color: grey, $amount: 20);
      }
    }
  }
}
.status:last-child .vertical:last-child {
  border-left: 0px;
}

.order-status__map {
  width: 60%;
}
