$modalTranslate: 40px;
$modalScale: 1;

.ReactModal__Overlay {
  opacity: 0;

  .ReactModal__Content {
    transform: translateY($modalTranslate) scale($modalScale);
  }
}

.ReactModal__Overlay--after-open{
  opacity: 1;

  .ReactModal__Content {
    transform: translateY(0) scale(1);
  }
}

.ReactModal__Overlay--before-close{
  opacity: 0;

  .ReactModal__Content {
    transform: translateY($modalTranslate) scale($modalScale);
  }
}
