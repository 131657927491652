@import 'base';
@import 'modal';
@import './components/order-status';

@import '~react-date-range/dist/styles.css'; // main css file
@import '~react-date-range/dist/theme/default.css'; // theme css file

.react-tiny-popover-container {
  z-index: 101;
}
